import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    showButton && (
      <div className="fixed bottom-4 right-4 z-50">
        <button onClick={scrollToTop} className="p-3 rounded-full bg-[#6C8E8A] text-white shadow-lg hover:bg-[#507268] focus:outline-none">
          <FaArrowUp size={20} />
        </button>
      </div>
    )
  );
};

export default ScrollToTop;
