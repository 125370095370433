import React from 'react';
import About from './components/About';
import Services from './components/Services';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Slide from './components/Slide';
import Projects from './components/Projects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import ScrollToTop from './components/ScrollToTop'; // Import the ScrollToTop component

function WhatsAppButton() {
  const openWhatsApp = () => {
    window.open(`https://wa.me/+250788917742?text=Hello!`, '_blank');
  };

  return (
    <button onClick={openWhatsApp} className="fixed bottom-4 left-4 bg-[#6C8E8A] text-white px-2 py-2 rounded-full shadow-md flex items-center">
      <FontAwesomeIcon icon={faWhatsapp} className="m-2" />
    </button>
  );
}

function App() {
  return (
    <div>
      <Navbar />
      <Slide />
      <Home />
      <About />
      <Services />
      <Projects />
      <Footer />
      <WhatsAppButton />
      <ScrollToTop /> {/* Add ScrollToTop component */}
    </div>
  );
}

export default App;
