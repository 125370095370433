import React from 'react';
import mpore from '../assets/projects/mpore.png'
import kcl from '../assets/projects/kcl.png'
import axon from '../assets/projects/axon.jpg'
import utc from '../assets/projects/utc.jpg'
import echurch from '../assets/projects/echurch.jpg'
import map from '../assets/projects/map.png'
import rp from '../assets/projects/rp.jpg'
import huska from '../assets/projects/huska.png'
import ability from '../assets/projects/ability.png'
import renerg from '../assets/projects/renerg.png'
import nyamurindacoffee from '../assets/projects/nyamurindacoffee.png'
import lapervenche from '../assets/projects/lapervenche.png'

const Projects = () => {
  const openWhatsApp = () => {
    window.open(`https://wa.me/+250788917742?text=Hello!`, '_blank');
  };
  return (
    <div id="projects" className='w-full pb-[10rem] md:pt-16 2xl:pt-[10px]  bg-[#fff]'>
    <div className='w-full text-white mb-10'>
   <div className='max-w-[1200px] md:w-[100%] md:rounded  py-8 mx-auto grid grid-cols-1 md:grid-cols-1'>
      <div className='md:col-span-1 flex flex-col items-center justify-center'><br></br>
         <h1 className='text-3xl md:text-4xl text-[#0F1B1B] font-bold'>Our Recently Projects</h1>
    <br></br>
    <b className='text-[#0F1B1B]'>------------------</b>
      </div>
   </div>
 </div>


   <div className='max-w-[1240px] px-6 mx-auto grid md:grid-cols-4 gap-8  '>
       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={echurch} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>eChurch Platform</h2>
    
       </div>

       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={axon} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>Axon Tunga Mobile App</h2>
    
       </div>
      
       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={utc} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>UTC FX Trading App</h2>
    
       </div>
       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={huska} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>Huska Website</h2>
      </div>
       
   </div>

   <div className='max-w-[1240px] px-6 mx-auto grid md:grid-cols-4 gap-8  '>
       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={mpore} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>Mpore Fashion E~commerce</h2>
      </div>

       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={kcl} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>Kigali Coffee  E~commerce</h2>
       </div>

       
      <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={map} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>Ride Share App</h2>
    
       </div>
       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={rp} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>RP-Corporate Website</h2>
     </div>
   </div>

   <div className='max-w-[1240px] px-6 mx-auto grid md:grid-cols-4 gap-8  '>
       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={ability} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>Ability Counselling Website</h2>
      </div>

       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={renerg} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>RENERG Rwanda Website</h2>
       </div>

       
      <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={nyamurindacoffee} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>Nyamurinda Coffee Website</h2>
    
       </div>
       <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
           <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={lapervenche} alt="/" />
           <h2 className='text-l font-bold text-center py-8'>Lapervenche Nutrition  Website</h2>
     </div>
   </div>
 </div>
  );
};

export default Projects;



