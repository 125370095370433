import React from 'react';
import Laptop from '../assets/eno.webp';

const About = () => {
  const openWhatsApp = () => {
    window.open(`https://wa.me/+250788917742?text=Hello!`, '_blank');
  };

  return (
    <div id="about" className='w-full md:h-[85vh] bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
      <div className='flex items-center justify-center h-full'>
        <img
          loading="lazy"
          decoding="async"
          fetchpriority="high"
          role="presentation"
          className='w-[700px] pr-4'
          src={Laptop}
          alt='Company'
        />
      </div>
        <div className='flex flex-col justify-center px-2'>
          <p className='text-[#6C8E8A] font-bold text-lg'>WHO WE ARE</p>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-[#0F1B1B]'>
          Empowering Your Digital Transformation
          </h1>
          <p className='py-2 text-gray-700'>
            At Enoveta Inc., we are dedicated to delivering top-notch IT solutions that drive innovation and excellence. Our team of experts is committed to providing tailored software development services, leveraging cutting-edge technologies to meet your unique business needs.
          </p>
          <p className='py-2 text-gray-700'>
            With a focus on quality and customer satisfaction, we offer a comprehensive suite of services including end-to-end software development, from analytics and architecture design to UX/UI design, quality assurance, deployment, and ongoing maintenance. Our goal is to ensure that your IT infrastructure runs seamlessly, empowering your business to thrive in a digital-first world.
          </p>
          <p className='py-2 text-gray-700'>
            Our approach combines technical expertise with a deep understanding of industry trends, allowing us to craft solutions that are not only functional but also forward-thinking. Whether you're looking to develop a new application, enhance an existing system, or optimize your IT processes, Enoveta Inc. is here to help you achieve your objectives with confidence.
          </p>
          <button
            onClick={openWhatsApp}
            className='bg-[#6C8E8A] text-white w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'
          >
            Connect with Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
