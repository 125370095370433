import React from 'react';
import soft from '../assets/soft.webp'
import consultancy from '../assets/consultancy.webp'
import webhosting from '../assets/webhosting.webp'

const Services = () => {
  const openWhatsApp = () => {
    window.open(`https://wa.me/+250788917742?text=Hello!`, '_blank');
  };
  return (
    <div id="services" className='w-full pb-[10rem] md:pt-16 2xl:pt-[10px]  bg-[#D9E3DF]'>
       <div className='w-full text-white mb-10'>
      <div className='max-w-[1200px] md:w-[100%] md:rounded  py-8 mx-auto grid grid-cols-1 md:grid-cols-1'>
         <div className='md:col-span-1 flex flex-col items-center justify-center'><br></br>
            <h1 className='text-3xl md:text-4xl text-[#0F1B1B] font-bold'>Our Awesome Services</h1>
       <br></br>
       <b className='text-[#0F1B1B]'>------------------</b>
         </div>
      </div>
    </div>
      <div className='max-w-[1240px] px-6 mx-auto grid md:grid-cols-3 gap-8  '>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-white hover:scale-105 duration-300'>
              <img loading="lazy"  decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={soft} alt="/" />
              <h2 className='text-2xl font-bold text-center py-8'>Software Development</h2>
              <p className='text-center'>We develop different kinds of software</p>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Web Application</p>
                  <p className='py-2 border-b mx-8'>Mobile Application</p>
                  <p className='py-2 border-b mx-8'>USSD Application</p>
                  <p className='py-2 border-b mx-8'>API Integration</p>
                  <p className='py-2 border-b mx-8'>Saas Application</p>
                  <p className='py-2 border-b mx-8'>Database Development</p>
                  <p className='py-2 border-b mx-8'>Web Design</p>
                  

              </div>
              <button onClick={openWhatsApp} className='bg-[#6C8E8A] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Let's Talk</button>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 bg-white rounded-lg hover:scale-105 duration-300'>
              <img loading="lazy" decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto bg-transparent bg-[#c1c1c1]' src={webhosting} alt="/" />
              <h2 className='text-2xl font-bold text-center py-8'>Web Hosting & Domain Registration</h2>
              <p className='py-2 border-b mx-8 text-center'>Welcome to our platform where we provide top-notch web hosting solutions and domain registration services. Whether you're launching a personal blog, setting up an e-commerce store, or managing a corporate website, our hosting plans cater to all your needs. Trust us to handle the technicalities while you focus on growing your digital footprint. Start your online journey with confidence today!</p>

              <div className='text-center font-medium'>
              </div>
              <button onClick={openWhatsApp} className='bg-[#6C8E8A] text-[#000] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Let's Talk</button>
          </div>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 bg-white rounded-lg hover:scale-105 duration-300'>
              <img loading="lazy" decoding="async" fetchpriority="high" role="presentation" className='w-[100%] mx-auto  bg-[#c1c1c1]' src={consultancy} alt="/" />
              <h2 className='text-2xl font-bold text-center py-8'>IT consultancy & <br></br>IT support</h2>
              <p className='py-2 border-b mx-8 text-center'>At Enoveta Inc, we specialize in providing tailored solutions to businesses seeking to navigate the complexities of the digital landscape. With our team of seasoned experts, we offer comprehensive guidance and support to optimize your IT infrastructure, streamline processes, and enhance overall efficiency. Partner with us to embark on a transformative journey towards innovation in today's dynamic business.</p>
              <div className='text-center font-medium'>
              </div>
              <button onClick={openWhatsApp} className='bg-[#6C8E8A] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3'>Let's Talk</button>
          </div>
      </div>
    </div>
  );
};

export default Services;
