// import React, { useState } from 'react';
// import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
// import logo from '../assets/logo.webp';
// import { FaFacebook, FaInstagram, FaLinkedin, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';


// const Navbar = () => {
//   const [nav, setNav] = useState(false);

//   const handleNav = () => {
//     setNav(!nav);
//   };

//   return (
//     <div className="border-b md:border-1 bg-[#122b27] border-[#174b39ab]">

//     {/* top header */}
//     <div className="flex items-center justify-center h-9 md:h-10 px-4 md:py-2 bg-gradient-to-t from-[#061A1B] via-[#061A1B] to-[#061A1B] text-[#b4d1ce]">
//       {/* Social Media Icons */}
//       <div className="flex md:items-center md:pl-[1%] pl-[10%] space-x-4   pr-[10%] md:pr-[15%] text-[#a9d6d2]">
//         <FaFacebook size={14} />
//         <FaInstagram size={14} />
//         <FaLinkedin size={14} />
//       </div>
//       {/* Contact Info */}
//       <div className="flex items-center space-x-4 pr-[1%] md:pr-[15%] text-[#a9d6d2] text-sm">
//         {/* <FaPhoneAlt size={14} /> */}
//         {/* <p><a href="tel:+25078891772">+250 788 917 72</a></p> */}
//         <FaEnvelope size={12} />
//         <p><a href="mailto:info@enoveta.com">info@enoveta.com</a></p>
//       </div>
//       {/* Location */}
//       <div className="flex items-center text-sm space-x-4 md:visible invisible text-[#a9d6d2]">
//         <FaMapMarkerAlt size={14} />
//         <p><a href="#">Kigali, Gasabo</a></p>
//       </div>
//     </div>

//     <div className=' flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white '>
//     <img className='w-[80px] md:w-[90px] mt-0' src={logo} alt='ENOVETA'/>
//       <ul className='hidden md:flex'>
//       <li className='p-4'>Home</li>
//           <li className='p-4 '>About Us</li>
//           <li className='p-4'>Our Services</li>
//           <li className='p-4'>Our Works </li>
//           <li className='p-4'>Contact Us </li>
//       </ul>
//       <div onClick={handleNav} className='block md:hidden'>
//           {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
//       </div>
//       <ul className={nav ? 'z-[9999] fixed w-[250px] left-0 top-0 max-w-[100%] h-full border-r border-r-gray-900 bg-[#061A1B] ease-in-out duration-500' : 'ease-in-out duration-500 z-[9999] absolute left-[-100%]'}>
//       <img className='w-[100px] ml-[80px] mt-5' src={logo} alt='ENOVETA'/>
//           <li className='p-4 border-b border-gray-600'>Home</li>
//           <li className='p-4 border-b border-gray-600'>About Us</li>
//           <li className='p-4 border-b border-gray-600'>Our Services</li>
//           <li className='p-4 border-b border-gray-600'>Our Works </li>
//           <li className='p-4 border-b border-gray-600'>Contact Us </li>
//           </ul>
//     </div>
//     </div>
//   );
// };

// export default Navbar;

// import React, { useState } from 'react';
// import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
// import logo from '../assets/logo.webp';
// import { FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

// const Navbar = () => {
//   const [nav, setNav] = useState(false);

//   const handleNav = () => {
//     setNav(!nav);
//   };

//   return (
//     <div className="border-b md:border-1 bg-[#122b27] border-[#174b39ab]">

//       {/* Top Header */}
//       <div className="flex items-center justify-center h-9 md:h-10 px-4 md:py-2 bg-gradient-to-t from-[#061A1B] via-[#061A1B] to-[#061A1B] text-[#b4d1ce]">
//         <div className="flex md:items-center md:pl-[1%] pl-[10%] space-x-4 pr-[10%] md:pr-[15%] text-[#a9d6d2]">
//           <FaFacebook size={14} />
//           <FaInstagram size={14} />
//           <FaLinkedin size={14} />
//         </div>
//         <div className="flex items-center space-x-4 pr-[1%] md:pr-[15%] text-[#a9d6d2] text-sm">
//           <FaEnvelope size={12} />
//           <p><a href="mailto:info@enoveta.com">info@enoveta.com</a></p>
//         </div>
//         <div className="flex items-center text-sm space-x-4 md:visible invisible text-[#a9d6d2]">
//           <FaMapMarkerAlt size={14} />
//           <p><a href="#">Kigali, Gasabo</a></p>
//         </div>
//       </div>

//       {/* Main Navbar */}
//       <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
//         <img className='w-[80px] md:w-[90px] mt-0' src={logo} alt='ENOVETA' />
//         <ul className='hidden md:flex'>
//           <li className='p-4'><a href="#home">Home</a></li>
//           <li className='p-4'><a href="#about">About Us</a></li>
//           <li className='p-4'><a href="#services">Our Services</a></li>
//           <li className='p-4'><a href="#projects">Our Works</a></li>
//           <li className='p-4'><a href="#contact">Contact Us</a></li>
//         </ul>
//         <div onClick={handleNav} className='block md:hidden'>
//           {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
//         </div>
//         <ul className={nav ? 'z-[9999] fixed w-[250px] left-0 top-0 max-w-[100%] h-full border-r border-r-gray-900 bg-[#061A1B] ease-in-out duration-500' : 'ease-in-out duration-500 z-[9999] absolute left-[-100%]'}>
//           <img className='w-[100px] ml-[80px] mt-5' src={logo} alt='ENOVETA' />
//           <li className='p-4 border-b border-gray-600'><a href="#home">Home</a></li>
//           <li className='p-4 border-b border-gray-600'><a href="#about">About Us</a></li>
//           <li className='p-4 border-b border-gray-600'><a href="#services">Our Services</a></li>
//           <li className='p-4 border-b border-gray-600'><a href="#projects">Our Works</a></li>
//           <li className='p-4 border-b border-gray-600'><a href="#contact">Contact Us</a></li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default Navbar;






import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import logo from '../assets/logo.webp';
import { FaFacebook, FaInstagram, FaLinkedin, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const closeMenu = () => {
    setNav(false);
  };

  return (
    <div className="border-b md:border-1 bg-[#122b27] border-[#174b39ab]">

      {/* Top Header */}
      <div className="flex items-center justify-center h-9 md:h-10 px-4 md:py-2 bg-gradient-to-t from-[#061A1B] via-[#061A1B] to-[#061A1B] text-[#b4d1ce]">
        <div className="flex md:items-center md:pl-[1%] pl-[10%] space-x-4 pr-[10%] md:pr-[15%] text-[#a9d6d2]">
          <FaFacebook size={14} />
          <FaInstagram size={14} />
          <FaLinkedin size={14} />
        </div>
        <div className="flex items-center space-x-4 pr-[1%] md:pr-[15%] text-[#a9d6d2] text-sm">
          <FaEnvelope size={12} />
          <p><a href="mailto:info@enoveta.com">info@enoveta.com</a></p>
        </div>
        <div className="flex items-center text-sm space-x-4 md:visible invisible text-[#a9d6d2]">
          <FaMapMarkerAlt size={14} />
          <p><a href="#">Kigali, Gasabo</a></p>
        </div>
      </div>

      {/* Main Navbar */}
      <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
        <img className='w-[80px] md:w-[90px] mt-0' src={logo} alt='ENOVETA' />
        <ul className='hidden md:flex'>
          <li className='p-4'><a href="/">Home</a></li>
          <li className='p-4'><a href="#about">About Us</a></li>
          <li className='p-4'><a href="#services">Services</a></li>
          <li className='p-4'><a href="#projects">Our Work</a></li>
          <li className='p-4'><a href="#contact">Contact Us</a></li>
        </ul>
        <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <ul className={nav ? 'z-[9999] fixed w-[250px] left-0 top-0 max-w-[100%] h-full border-r border-r-gray-900 bg-[#061A1B] ease-in-out duration-500' : 'ease-in-out duration-500 z-[9999] absolute left-[-100%]'}>
          <img className='w-[100px] ml-[80px] mt-5' src={logo} alt='ENOVETA' />
          <li onClick={closeMenu} className='p-4 border-b border-gray-600'><a href="#home">Home</a></li>
          <li onClick={closeMenu} className='p-4 border-b border-gray-600'><a href="#about">About Us</a></li>
          <li onClick={closeMenu} className='p-4 border-b border-gray-600'><a href="#services">Our Services</a></li>
          <li onClick={closeMenu} className='p-4 border-b border-gray-600'><a href="#projects">Our Works</a></li>
          <li onClick={closeMenu} className='p-4 border-b border-gray-600'><a href="#contact">Contact Us</a></li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
