import React from 'react';
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,

} from 'react-icons/fa';

const Footer = () => {
  // Function to get the current year
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div id="contact" className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
      <div>
        <h1 className='w-full text-3xl font-bold text-[#6C8E8A]'>ENOVETA Inc.</h1>
        <p className='py-4'>Explore limitless possibilities with Enoveta Inc! Need software development or any tech solution? we're here to innovate with you. Reach out today and discover how our committed team can bring your ideas to life</p>
        <div className='flex justify-center md:w-[35%] my-6 justify-between md:px-[5px] px-10'>
            <FaFacebookSquare size={18} />
            <FaInstagram size={18} />
            <FaLinkedinIn size={18} />
        </div>
      </div>
      <div className='md:flex lg:col-span-2 hidden justify-between mt-6'>
        <div>
          <h6 className='font-medium text-gray-400'>Contact Us</h6>
          <ul>
            <li className='py-2 text-sm'><b>Tel: </b>+250 788 917 742</li>
            <li className='py-2 text-sm'><b>Email: </b>info@enoveta.com</li>
          </ul>
        </div>
        <div>
          <h6 className='font-medium text-gray-400'>Address</h6>
          <ul>
            <li className='py-2 text-sm'>Gisozi KG 805 Street</li>
            <li className='py-2 text-sm'>Kigali, Gasabo</li>
            <li className='py-2 text-sm'>Rwanda</li>
          </ul>
        </div>
        <div className=''>
          <h6 className='font-medium text-gray-400'>Our Services</h6>
          <ul>
            <li className='py-2 text-sm'>Software Development</li>
            <li className='py-2 text-sm'>IT consultancy</li>
            <li className='py-2 text-sm'>Hosting</li>
            <li className='py-2 text-sm'>IT Support</li>
            <li className='py-2 text-sm'>dApp Development</li>
          </ul>
        </div>
      </div>
      <hr className='w-[100%]'/>
      {/* Copyright statement */}
      <div className="w-full text-center text-sm text-gray-500 mt-1">
        &copy; {getCurrentYear()} Enoveta Inc. All rights reserved.
      </div>
      <hr className='w-[100%] invisible md:visible'/>
    </div>
  );
};

export default Footer;
