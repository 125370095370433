import React from 'react';
import { ReactTyped as Typed } from 'react-typed';


const Home = () => {
  const openWhatsApp = () => {
    window.open(`https://wa.me/+250788917742?text=Hello!`, '_blank');
  };
  return (
    <div className='text-white'>
      <div className=' max-w-[1000px] mt-[100px] md:mt-[100px] 2xl:mt-[-40px]  w-full md:h-screen h-[70vh] mb-[-10px] md:mb-[-170px] md:pb-[10px] mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#00df9a] font-bold p-2'>
          {/* GROWING WITH DATA ANALYTICS */}
        </p>
        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
          {/* Grow with data. */}
        </h1>
        <div className=' justify-center items-center'>
          <p className='md:text-2xl  text-l font-bold ml-10 mr-10 py-4'>
          Enoveta Inc. is an innovative IT solutions company specializing in software development and technology solutions.          </p>
                 <p className='md:text-xl text-l font-bold text-[#6F918E] ml-10 mr-10'>We provide the following services:


</p>

        </div>
        <div className='flex justify-center items-center'>

        <Typed
          className='md:text-4xl pt-5 text-xl font-bold md:pl-4 pl-2'
            strings={['WEB APP DEVELOPMENT', 'MOBILE APP DEVELOPMENT', 'WEB DESIGN', 'USSD APP DEVELOPMENT', 'WEB HOSTING', 'IT SUPPORT', 'IT CONSULTANCY']}
            typeSpeed={120}
            backSpeed={40}
            loop
          />
          </div>
        {/* <p className='md:text-2xl text-xl font-bold text-gray-500 ml-10 mr-10'>Monitor your data analytics to increase revenue for BTB, BTC, & SASS platforms.</p> */}
        <button onClick={openWhatsApp} className='bg-[#6C8E8A] w-[110px] md:w-[150px] rounded-md font-small my-6 mx-auto py-3  text-black'>Let's Talk</button>
      </div>
    </div>
  );
};

export default Home;
