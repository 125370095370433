import React, { useState } from 'react';
import Css from '../assets/css/Css.css';


function Slide() {
    return (
     
        <div className="visible md:visible container">
        <div className="carousel">
          <div className="carousel__face"><span id='span'></span></div>
          <div className="carousel__face"><span></span></div>
          <div className="carousel__face"><span></span></div>
          <div className="carousel__face"><span></span></div>
          <div className="carousel__face"><span></span></div>
          <div className="carousel__face"><span></span></div>
          <div className="carousel__face"><span></span></div>
          <div className="carousel__face"><span></span></div>
          <div className="carousel__face"><span></span></div>
        </div>
      </div>
    );
}

export default Slide;